<template>
  <div class="flex justify-center items-center my-4">
    <step-button
      v-for="(step, index) in steps"
      :title="step.title"
      :key="`step-${index}`"
      :index="+index"
      :current="current"
      @click.prevent="setCurrentIndex(+index)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import StepButton from './StepButton.vue'

export default defineComponent({
  components: {
    StepButton
  },
  props: {
    steps: {
      type: Object,
      required: true
    },
    current: {
      type: Number,
      required: true
    }
  },
  emits: [
    'step-change'
  ],
  setup (props, { emit }) {
    const setCurrentIndex = (index: number) => {
      if (index < props.current) {
        emit('step-change', index)
      }
    }

    return {
      setCurrentIndex
    }
  }
})
</script>
