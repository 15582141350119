
import { defineComponent } from 'vue'
import StepButton from './StepButton.vue'

export default defineComponent({
  components: {
    StepButton
  },
  props: {
    steps: {
      type: Object,
      required: true
    },
    current: {
      type: Number,
      required: true
    }
  },
  emits: [
    'step-change'
  ],
  setup (props, { emit }) {
    const setCurrentIndex = (index: number) => {
      if (index < props.current) {
        emit('step-change', index)
      }
    }

    return {
      setCurrentIndex
    }
  }
})
