<template>
  <button class="flex items-center justify-center min-w-6 h-6 border rounded-full cursor-pointer mx-2 border-gray-500 focus:outline-none">
    <slot/>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
