<template>
  <div v-if="isReady" class="wrapper">
    <wizard-steps
      :steps="steps"
      :current="currentIndex"
      @step-change="setCurrentIndex"
    />
    <h2 class="text-center mb-6" v-html="currentTitle"></h2>
    <div class="w-full flex flex-col items-center justify-center">
      <div
        class="flex-1 flex items-center justify-center container mb-5"
      >
        <keep-alive>
          <component
            :is="currentComponent"
            :form="form"
            @update:form="updateForm"
            @final-step="finishWizard"
          />
        </keep-alive>
      </div>
      <div class="w-full mb-5 flex justify-between">
        <div>
          <button
            @click.prevent="previousTab"
            v-if="!isFirstStep && !isLastStep"
            type="button"
            class="btn"
          >
            {{ $t('action.back') }}
          </button>
        </div>
        <div>
          <button
            v-if="!isLastStep"
            @click.prevent="nextTab"
            type="button"
            class="btn btn-primary"
          >
            {{ $t('action.continue') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t('sentence.loading') }}
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  readonly,
  ref,
  unref,
} from 'vue'
import WizardSteps from '@/components/wizard/WizardSteps.vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useToggle } from '@/composables/useToggle'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    WizardSteps
  },
  setup () {
    const router = useRouter()

    const store = useStore()

    const { t } = useI18n()

    const { profile } = useProfile()

    const {
      isActive: isReady,
      setActive: setReady,
    } = useToggle(false)

    const form = ref({
      website: '',
      competitors: [],
      category: null,
      keywords: [],
      description: '',
      specialization: '',
      audience: '',
      voiceTone: {
        humor: '',
        formality: '',
        respectfulness: '',
        enthusiasm: '',
      },
      contentPurpose: '',
      profileProduct: '',
      language: '',
    })

    const tabs = [
      {
        component: defineAsyncComponent(() => import('@/components/profile/wizardSteps/BusinesStep.vue')),
        title: t('page.wizard.sentence.busines_step_title'),
        rules: {
          category: {
            required
          },
          language: {
            required
          }
        }
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/wizardSteps/DescriptionStep.vue')),
        title: t('page.wizard.sentence.description_step_title'),
        rules: {
          specialization: {
            required,
            wordsCount: (value: any) => `${value}`.trim().length >= 20
          },
          audience: {
            required,
            wordsCount: (value: any) => `${value}`.trim().length >= 20
          },
          contentPurpose: {
            required,
            wordsCount: (value: any) => `${value}`.trim().length >= 20
          },
          profileProduct: {
            required,
            wordsCount: (value: any) => `${value}`.trim().length >= 20
          },
        }
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/wizardSteps/CompetitorsStep.vue')),
        title: t('page.wizard.sentence.competitors_step_title')
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/wizardSteps/KeywordsStep.vue')),
        title: t('page.wizard.sentence.keywords_step_title-html'),
        rules: {
          keywords: {
            required,
            keywordsCount: (value: any) => Object.keys(value).length === 3
          }
        }
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/wizardSteps/SocialAccounts.vue')),
        title: t('page.settings.social_accounts.title'),
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/wizardSteps/FinalStep.vue')),
        title: t('page.wizard.sentence.final_step_title')
      },
    ]

    const steps = readonly(tabs.map((tabs, index) => ({
      key: `step-${index}`,
      index
    })))

    const currentIndex = ref(unref(profile).step || 0)

    const isFirstStep = computed(() => unref(currentIndex) === 0)

    const isLastStep = computed(() => unref(currentIndex) === steps.length - 1)

    const currentTab = computed(() => {
      return tabs[unref(currentIndex)]
    })

    const currentComponent = computed(() => {
      return unref(currentTab).component
    })

    const currentTitle = computed(() => {
      return unref(currentTab).title
    })

    const setCurrentIndex = (index: number) => {
      currentIndex.value = index
    }

    const saveProfile = async (step = currentIndex.value) => {
      const cleanForm = Object.entries(form.value)
        .filter((obj) => obj[1] !== undefined)
        .reduce((prev, [key, value]) => {
          return {
            ...prev,
            [key]: value
          }
        }, {})

      await store.dispatch('profiles/updateCurrent', {
        ...cleanForm,
        step
      })
    }

    const previousTab = async () => {
      if (unref(currentIndex) > 0) {
        currentIndex.value--
        await saveProfile()
      }
    }

    const validateRules = ref()

    const $v = useVuelidate(validateRules, form)

    const validatorMessages: { validation: string; message: string }[] = [
      {
        validation: 'category.required',
        message: t('page.settings.category.form.category.empty_error_message')
      },
      {
        validation: 'language.required',
        message: t('page.settings.profile-language.form.profile-language.empty_error_message')
      },
      {
        validation: 'keywords.required',
        message: t('page.settings.category.form.keyword.empty_error_message')
      },
      {
        validation: 'keywords.keywordsCount',
        message: t('page.settings.category.form.keyword.empty_error_message')
      },
      {
        validation: 'specialization.required',
        message: t('page.settings.about_my_business.form.specialization.empty_error_message', {min: 20})
      },
      {
        validation: 'specialization.wordsCount',
        message: t('page.settings.about_my_business.form.specialization.empty_error_message', {min: 20})
      },
      {
        validation: 'audience.required',
        message: t('page.settings.about_my_business.form.audience.empty_error_message', {min: 20})
      },
      {
        validation: 'audience.wordsCount',
        message: t('page.settings.about_my_business.form.audience.empty_error_message', {min: 20})
      },
      {
        validation: 'contentPurpose.required',
        message: t('page.settings.about_my_business.form.content_purpose.empty_error_message', {min: 20})
      },
      {
        validation: 'contentPurpose.wordsCount',
        message: t('page.settings.about_my_business.form.content_purpose.empty_error_message', {min: 20})
      },
      {
        validation: 'profileProduct.required',
        message: t('page.settings.about_my_business.form.content_purpose.empty_error_message', {min: 20})
      },
      {
        validation: 'profileProduct.wordsCount',
        message: t('page.settings.about_my_business.form.content_purpose.empty_error_message', {min: 20})
      }
    ]

    const nextTab = async () => {
      if (currentIndex.value < steps.length - 1) {

        $v.value.$touch()

        const rules = tabs[currentIndex.value].rules

        if (rules) {
          validateRules.value = rules
          await $v.value.$validate()
          await $v.value.$validate()

          if ($v.value.$invalid) {
            const { $property, $validator } = $v.value.$errors[0]

            const errorMessage = validatorMessages.find(({ validation }) => validation === `${$property}.${$validator}`)

            Swal.fire({
              title: t('sentence.warning'),
              text: errorMessage !== undefined
                ? errorMessage?.message
                : t('sentence.complete_all_fields')
            })

            return
          }
        }

        currentIndex.value++
        await saveProfile()
      }
    }

    const updateForm = (value: any) => {
      form.value = {
        ...form.value,
        ...value
      }

      $v.value.$touch()
    }

    const finishWizard = async (routeName: string) => {
      await saveProfile(-1)

      router.replace({ name: routeName })
    }

    onMounted(async () => {
      const { step } = unref(profile)

      if (step === -1) {
        router.replace({ name: 'home' })
        return
      }

      const {
        website,
        category,
        competitors = [],
        keywords = [],
        description,
        specialization = '',
        audience = '',
        voiceTone = {
          humor: '',
          formality: '',
          respectfulness: '',
          enthusiasm: '',
        },
        contentPurpose = '',
        profileProduct = '',
      } = unref(profile)

      updateForm({ website, category, competitors, keywords, description, specialization, audience, voiceTone, contentPurpose, profileProduct })

      setReady()
    })

    return {
      isReady,
      currentComponent,
      currentIndex,
      currentTitle,
      finishWizard,
      form,
      isFirstStep,
      isLastStep,
      nextTab,
      previousTab,
      setCurrentIndex,
      steps,
      updateForm,
    }
  }
})
</script>

<style scoped>
  @layer components {
    .wrapper {
      @apply flex mx-auto flex-col items-center h-full;
      width: min(100%, 36rem);
    }
  }
</style>
