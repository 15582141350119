<template>
  <base-button
    :class="classes"
    v-html="content"
  />
</template>

<script lang="ts">
import { computed, defineComponent, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseButton from './BaseButton.vue'

export default defineComponent({
  components: {
    BaseButton
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const { t } = useI18n()

    const isPrevious = computed(() => props.index < props.current)

    const isNext = computed(() => props.index > props.current)

    const classes = computed(() => {
      if (unref(isPrevious)) {
        return ['bg-green-400', 'border-green-400', 'text-white']
      } else if (unref(isNext)) {
        return ['cursor-not-allowed']
      } else {
        return ['w-auto', 'p-4', 'uppercase', 'font-semibold', 'text-sm', 'border-blue-600', 'text-blue-600']
      }
    })

    const content = computed(() => {
      if (unref(isPrevious)) {
        return '<i class="fas fa-check text-xs"></i>'
      } else if (unref(isNext)) {
        return ''
      } else {
        if (props.title) {
          return props.title
        }
        return t('page.wizard.sentence.step_button-dynamic', { step: props.index + 1 })
      }
    })

    return {
      classes,
      content
    }
  }
})
</script>
